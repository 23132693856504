<script>
import axios from 'axios'
import $sock from '@/components/WebSocket' //聊天的js文件
import qs from 'qs'
import { loginByUsername, getApiUrl, getVerityCode, register, applyAgent, getAgreement } from '@/api/login';
export default {
    name:'Login',
    components:{
        Header: () => import('components/base/Header'),      

        'mt-button':app.Mint.Button,
        'mt-switch':app.Mint.Switch,
    },
    data() {
        return {
            account: '',
            password: '',
            promoCode:null,
            promoCodeReq:false,
            regist_account: '',
            regist_password: '',
            regist_name: '',
            active: 'login',
            username: '',
            value: false,
            logoUrl:null,
            
            header:{
                mainTitle:getWord('sign_in'),
                headerMenu:false,
                backButton:false
            },
            passwordInputType:'password',
            passwordInputType2:'password',

            phoneNumber:null,
            qqNumber:null,
            wxNumber:null,
            emailAddress:null,
            withdrawPassword:null,
            verifyCode:null,
            verifyImage:null,
            _uuid:null,
                pinCode:null,
                passwordVerify:null,
            regdone:null,

            agreementToggle:false,
            agreementContent:null,
        }

    },
    mounted() {
        if (this.$route.query.sign && this.$route.query.sign==='reg') {
            this.active = 'reg'
        }        
        if (window.localStorage.getItem('logourl')) {
            this.logoUrl=window.localStorage.getItem('logourl');
        }
        if (window.sessionStorage.getItem('urlPromoCode')) {
            this.promoCode=window.sessionStorage.getItem('urlPromoCode');
        }
        this.nextVerifyImage();

        getAgreement({
            'type':'4',
        }).then(result=>{
            if (result.data.code === "SUCCESS" && result.data.result) {
                this.agreementContent=result.data.result.list[0].content
            }
        })
    },
    computed:{
        urlPromoCode(){
            return window.sessionStorage.getItem('urlPromoCode');
        }
    },
    created(){
        if (this.$route.query.selected==="2") {
            this.active='reg';
            this.header.mainTitle=getWord('regist_agent');
            this.header.mainTitleId='2';
        }
    },
    methods: {
        checkRequired(key,checkExist){
            var _=null;

            if (checkExist) {
                if (self.localStorage.getItem(key) && self.localStorage.getItem(key)!=="null") {
                    return true;
                }else {
                    return false;
                }
            }
            
            if (self.localStorage.getItem(key)) {
                self.localStorage.getItem(key)==='true'
                    ?_=true
                    :_=false;
            }else {
                _=false;
            }
            return _; 
        },
        //转换登录和注册的状态
        land() {
            this.active = 'login';
        },
        reg() {
            this.active = 'reg';
        },
        //登录
        test() {
            if (this.account.length == '') {
                app.Mint.Toast(getWord('username_rules10'));
            } else if (this.password.length == '') {
                app.Mint.Toast(getWord('password_rules11'));
            } else {
                this.login();
            }

        },
        login() {
            var that=this;

            loginByUsername(this.account, this.password)
                .then(result => {
                    if (result.data.code === 'SUCCESS') {
                        let user = {
                            token: result.data.result.token,
                            nickname: result.data.result.nickname,
                            recommend: result.data.result.recommend,
                            headImage: result.data.result.headImage,
                            username: result.data.result.username,
                            type: result.data.result.type,
                            wsurl: result.data.result.ws_url,
                            has_first_money: result.data.result.has_first_money,
                        };
                        localStorage.setItem('user', JSON.stringify(user));
                        localStorage.setItem('openid', 'anonymous');
                        that.$cookie.set('account', that.account, { expires: '1h' });
                        that.getGrade();
                        
                        var token=result.data.result.token;
                        if (wsConnection) {
                            console.log('ws closed.(LOGIN)')
                            wsConnection.close();
                            that.$router.push({ path: '/home' });
                        }
                    } else {
                        localStorage.setItem('user', '');
                        app.Mint.Toast(result.data.msg);
                    }
                })
        },
        //注册
        regist(event) {    
            var that=this;        
            if (this.active!=='reg') {
                this.active='reg';
                this.header.mainTitle=getWord('sign_up4');
            }else {
                var account_reg = /^[a-z0-9]{5,10}$/;
                var password_reg = /^[a-z0-9A-Z]{8,12}$/;
                var wtpassword_reg = /^[0-9]{6,8}$/;
                var promo_reg = /\S+/;
                var name_reg = /^[\u4E00-\u9FA5]+$/;

                if (!this.regist_account.match(account_reg)) {
                    app.Mint.Toast(getWord('username_rules',{
                        $1:'5',
                        $2:'10'
                    }));
                    return false;
                } 

                if ( !this.regist_password.match(password_reg) ) {
                    app.Mint.Toast(getWord('password_rules',{
                        $1:'8',
                        $2:'12'
                    }));
                    return false;
                }

                if (this.header.mainTitleId!=='2') {
                    if (this.checkRequired('RECOMMENDCODE_REQUIRED') && (this.promoCode===null || !this.promoCode.match(promo_reg)) ) {
                        app.Mint.Toast(getWord('fill_promo_id2'));
                        return false;
                    }
                    
                    if ((this.checkRequired('WTPASSWORD_REQUIRED') && this.header.mainTitleId!=='2') && (this.withdrawPassword===null || !this.withdrawPassword.match(wtpassword_reg)) ) {
                        app.Mint.Toast(getWord('password_rules7'));
                        return false;
                    }

                    if (this.checkRequired('NAME_REQUIRED') && (this.regist_name===null || !this.regist_name.match(name_reg)) ) {
                        app.Mint.Toast(getWord('username_rules11'));
                        return false;
                    }            
                    if (this.checkRequired('PHONE_REQUIRED') && (this.phoneNumber===null || !this.phoneNumber.match(promo_reg)) ) {
                        app.Mint.Toast(getWord('fill_mobile_phone'));
                        return false;
                    }
                    if (this.checkRequired('QQ_REQUIRED') && (this.qqNumber===null || !this.qqNumber.match(promo_reg)) ) {
                        app.Mint.Toast(getWord('fill_social_app1_account'));
                        return false;
                    }
                    if (this.checkRequired('EMAIL_REQUIRED') && (this.emailAddress===null || !this.emailAddress.match(promo_reg)) ) {
                        app.Mint.Toast(getWord('fill_email'));
                        return false;
                    }
                    if (this.checkRequired('WX_REQUIRED') && (this.wxNumber===null || !this.wxNumber.match(promo_reg)) ) {
                        app.Mint.Toast(getWord('fill_social_app2_account'));
                        return false;
                    }
                    if (this.checkRequired('VERITYCODE_REQUIRED') && (this.verifyCode===null || !this.verifyCode.match(promo_reg)) ) {
                        app.Mint.Toast(getWord('validation_code_rules'));
                        return false;
                    }
                }else {
                    if (this.checkRequired('AGENT_NAME_REQUIRED') && (this.regist_name===null || !this.regist_name.match(name_reg)) ) {
                        app.Mint.Toast(getWord('username_rules11'));
                        return false;
                    }            
                    if (this.checkRequired('AGENT_PHONE_REQUIRED') && (this.phoneNumber===null || !this.phoneNumber.match(promo_reg)) ) {
                        app.Mint.Toast(getWord('fill_mobile_phone'));
                        return false;
                    }
                    if (this.checkRequired('AGENT_QQ_REQUIRED') && (this.qqNumber===null || !this.qqNumber.match(promo_reg)) ) {
                        app.Mint.Toast(getWord('fill_social_app1_account'));
                        return false;
                    }
                    if (this.checkRequired('AGENT_EMAIL_REQUIRED') && (this.emailAddress===null || !this.emailAddress.match(promo_reg)) ) {
                        app.Mint.Toast(getWord('fill_email'));
                        return false;
                    }
                    if (this.checkRequired('AGENT_WX_REQUIRED') && (this.wxNumber===null || !this.wxNumber.match(promo_reg)) ) {
                        app.Mint.Toast(getWord('fill_social_app2_account'));
                        return false;
                    }
                }

                if (this.value == false && this.header.mainTitleId!=='2') {
                    app.Mint.Toast(getWord('regist_terms_confirm'));
                    return false;
                }

                if (this.header.mainTitleId!=='2') {
                    if (event) {
                        event.target.style.display='none';
                    }
                    register(
                        this.promoCode,
                        this.regist_account, 
                        this.regist_password, 
                        this.withdrawPassword,

                        this.regist_name, 
                        this.phoneNumber,
                        this.qqNumber,
                        this.emailAddress,
                        this.wxNumber,

                        this._uuid,
                        this.verifyCode,
                    ).then(result => {
                        if (result.data.code === 'SUCCESS') {
                            let user = {
                                token: result.data.result.token,
                                nickname: result.data.result.nickname,
                                recommend: result.data.result.recommend,
                                headImage: result.data.result.headImage,
                                username: result.data.result.username,
                                type: result.data.result.type,
                                wsurl: result.data.result.ws_url,
                                has_first_money: result.data.result.has_first_money,
                            };
                            localStorage.setItem('user', JSON.stringify(user));
                            localStorage.setItem('openid', 'anonymous');
                            this.getGrade();
                            this.$cookie.set('account', this.regist_account, { expires: '1h' });
                            this.$router.push({ path: '/home' });
                            app.Mint.Toast(getWord('regist_success'));
                        } else {
                            this.nextVerifyImage();
                            app.Mint.Toast(result.data.msg);
                            setTimeout(function(){
                                if (event) {
                                    event.target.style.display='block';
                                }
                            },1000);
                        }
                    })
                }else {
                    applyAgent(
                        this.regist_account, 
                        this.regist_password, 

                        this.regist_name, 
                        this.phoneNumber,
                        this.qqNumber,
                        this.emailAddress,
                        this.wxNumber,
                    ).then(result => {
                        if (result.data.code === 'SUCCESS') {
                            this.regdone=true;
                            app.Mint.Toast(getWord('regist_success'));
                            // this.$router.push({ path: '/home' });
                        } else {
                            app.Mint.Toast(result.data.msg);
                        }
                    })
                }
            }
        },
        togglePassword(item){
            this[item]=this[item]==='password'?'text':'password'
        },
        uuid() {
            var s = [];
            var hexDigits = "0123456789abcdef";
            for (var i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
            s[8] = s[13] = s[18] = s[23] = "-";

            var uuid = s.join("");
            this._uuid=uuid;
            return uuid;
        },
        nextVerifyImage(){
            getVerityCode(this.uuid()).then(result=>{
                this.verifyImage=window.URL.createObjectURL(result.data);
            })
        },
        forgotPass(){
            app.Mint.Toast(getWord("contact_service2"),1000)
        },
        showAgreement(){
            this.agreementToggle=!this.agreementToggle
        }
    },
    inject:[
        'getGrade',
        'setNewbiePrize',
    ],
}
</script>
<template>
    <section id="login" :class="_TEMPLATE">
        <!-- <form class="login"> -->
        <template v-if="_TEMPLATE==='template-1'">
              <Header :mainTitle="header.mainTitle" :headerMenu="header.headerMenu" :backButton=false :serviceIcon=true :backHome=true />
              
            <div class="myposition">
                <img :src="require('@@/assets/images2/user/login/ima2.png')" width="100%" />
            </div>
            <div class="main" :class="{'agent-reg':header.mainTitleId==='2'}">
                <div class="header">
                    <img :src="logoUrl" />
                </div>
                <div class="middle">
                    <div class="middle_firstbt">
                        <div class="middle_firstbt_1">
                            <a :class="{active:active==='login'}" @click="land()">{{ getWord('sign_in3') }}</a>
                        </div>
                        <div class="middle_firstbt_2">
                            <a :class="{active:active==='reg'}" @click="reg()">
                                <template v-if="header.mainTitleId!=='2'">{{ getWord('sign_up') }}</template>
                                <template v-else>{{ getWord('regist_agent') }}</template>
                            </a>
                        </div>
                    </div>
                    <div class="land_middle_text" v-if="active=='login'">
                        <div class="middle_text1">
                            <div class="middle_text1_image"></div>
                            <input @blur="_blured($event)" type="text" class="middle_text1_input" :placeholder="getWord('fill_username')" v-model="account" />
                        </div>
                        <div class="middle_text1">
                            <div class="middle_text2_image"></div>
                            <input @blur="_blured($event)" type="password" class="middle_text1_input" :placeholder="getWord('fill_password')" v-model="password" @keyup.13="test()" />
                        </div>
                        <mt-button size="normal" type="primary" class="mt-button1" @click="test()">{{ getWord('sign_in3') }}</mt-button>
                        <div class="middle_footer">
                            <a @click='Mint.Toast(getWord("contact_service2"),1000)' style="font-size: 0.24rem;font-weight:400;color:rgba(153,153,153,1);">{{ getWord('forget_password') }}?</a>
                        </div>
                    </div>
                    <div class="land_middle_text" v-if="active=='reg'">
                        <div class="middle_text1" v-if="checkRequired('RECOMMENDCODE_REQUIRED',true) && header.mainTitleId!=='2'" :class="{required:checkRequired('RECOMMENDCODE_REQUIRED')}">
                            <input @blur="_blured($event)" type="text" class="middle_text1_input" :placeholder="getWord('fill_promo_id')+(checkRequired('RECOMMENDCODE_REQUIRED')?'':'('+getWord('optional')+')')" v-model="promoCode" :disabled="urlPromoCode?true:false" />
                        </div>
                        <div class="middle_text1 required name">
                            <b v-if="header.mainTitleId==='2'">d</b>
                            <input @blur="_blured($event)" type="text" class="middle_text1_input" :placeholder="getWord('username_rules5',{
                                    $1:'5',
                                    $2:'10'
                                })" v-model="regist_account">
                        </div>
                        <div class="middle_text1 required">
                            <input @blur="_blured($event)" type="password" class="middle_text1_input" :placeholder="getWord('password_rules5',{
                                    $1:'8',
                                    $2:'12'
                                })" v-model="regist_password">
                        </div>
                        <div class="middle_text1" v-if="checkRequired('WTPASSWORD_REQUIRED',true) && header.mainTitleId!=='2'" :class="{required:checkRequired('WTPASSWORD_REQUIRED')}">
                            <input class="middle_text1_input" @blur="_blured($event)" :type="passwordInputType2" :placeholder="getWord('password_rules7',{
                                    $1:'6',
                                    $2:'8'
                                })" v-model="withdrawPassword" />
                        </div>
                        <template v-if="header.mainTitleId!=='2'">
                            <div class="middle_text1" v-if="checkRequired('NAME_REQUIRED',true)" :class="{required:checkRequired('NAME_REQUIRED')}">
                                <input class="middle_text1_input" @blur="_blured($event)" type="text" :placeholder="getWord('real_name_rules')" v-model="regist_name">
                            </div>
                            <div class="middle_text1" v-if="checkRequired('PHONE_REQUIRED',true)" :class="{required:checkRequired('PHONE_REQUIRED')}">
                                <input class="middle_text1_input" @blur="_blured($event)" type="text" :placeholder="getWord('fill_mobile_phone')" v-model="phoneNumber" />
                            </div>
                            <div class="middle_text1" v-if="checkRequired('QQ_REQUIRED',true)" :class="{required:checkRequired('QQ_REQUIRED')}">
                                <input class="middle_text1_input" @blur="_blured($event)" type="text" :placeholder="getWord('fill_social_app1_account')" v-model="qqNumber" />
                            </div>
                            <div class="middle_text1" v-if="checkRequired('EMAIL_REQUIRED',true)" :class="{required:checkRequired('EMAIL_REQUIRED')}">
                                <input class="middle_text1_input" @blur="_blured($event)" type="text" :placeholder="getWord('fill_email')" v-model="emailAddress" />
                            </div>
                            <div class="middle_text1" v-if="checkRequired('WX_REQUIRED',true)" :class="{required:checkRequired('WX_REQUIRED')}">
                                <input class="middle_text1_input" @blur="_blured($event)" type="text" :placeholder="getWord('fill_social_app2_account')" v-model="wxNumber" />
                            </div>
                        </template>
                        <template v-else>
                            <div class="middle_text1" v-if="checkRequired('AGENT_NAME_REQUIRED',true)" :class="{required:checkRequired('AGENT_NAME_REQUIRED')}">
                                <input class="middle_text1_input" @blur="_blured($event)" type="text" :placeholder="getWord('real_name_rules')" v-model="regist_name">
                            </div>
                            <div class="middle_text1" v-if="checkRequired('AGENT_PHONE_REQUIRED',true)" :class="{required:checkRequired('AGENT_PHONE_REQUIRED')}">
                                <input class="middle_text1_input" @blur="_blured($event)" type="text" :placeholder="getWord('fill_mobile_phone')" v-model="phoneNumber" />
                            </div>
                            <div class="middle_text1" v-if="checkRequired('AGENT_QQ_REQUIRED',true)" :class="{required:checkRequired('AGENT_QQ_REQUIRED')}">
                                <input class="middle_text1_input" @blur="_blured($event)" type="text" :placeholder="getWord('fill_social_app1_account')" v-model="qqNumber" />
                            </div>
                            <div class="middle_text1" v-if="checkRequired('AGENT_EMAIL_REQUIRED',true)" :class="{required:checkRequired('AGENT_EMAIL_REQUIRED')}">
                                <input class="middle_text1_input" @blur="_blured($event)" type="text" :placeholder="getWord('fill_email')" v-model="emailAddress" />
                            </div>
                            <div class="middle_text1" v-if="checkRequired('AGENT_WX_REQUIRED',true)" :class="{required:checkRequired('AGENT_WX_REQUIRED')}">
                                <input class="middle_text1_input" @blur="_blured($event)" type="text" :placeholder="getWord('fill_social_app2_account')" v-model="wxNumber" />
                            </div>
                        </template>
                        <div v-if="checkRequired('VERITYCODE_REQUIRED',true) && header.mainTitleId!=='2'" :class="{required:true}" class="verify-code middle_text1">
                            <input class="middle_text1_input" @blur="_blured($event)" type="text" :placeholder="getWord('fill_validation_code')" v-model="verifyCode" />
                            <img :src="verifyImage" @click="nextVerifyImage" />
                        </div>
                        <div class="middle_footer">
                            <mt-switch class="middle_footer_mt-switch2" v-model="value"><span style="font-size: 0.24rem;font-weight:400;color:rgba(153,153,153,1);">{{ getWord('regist_terms_kept_informed') }}<a style="font-size: 0.24rem;">{{ getWord('regist_agreement') }}</a></span></mt-switch>
                            <div class="middle_footer_text"></div>
                        </div>
                        <mt-button size="normal" type="primary" class="mt-button1" @click="regist($event)">{{ getWord('sign_up') }}</mt-button>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="_TEMPLATE==='template-2'">
            <Header :mainTitle="header.mainTitle" :headerMenu="header.headerMenu" :backButton=false :serviceIcon=true :backHome=true />
            <div class="main ui-2">
                <div v-if="active=='login'" class="login">
                    <div class="header">
                        <img :src="logoUrl" />
                    </div>
                    <div class="form-body">
                        <div class="fieldset clearfix">
                            <img :src="require('@@/assets/images/others/icons/username.png')" />
                            <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_username')" v-model="account" />
                        </div>
                        <div class="fieldset clearfix">
                            <img :src="require('@@/assets/images/others/icons/password.png')" />
                            <input @blur="_blured($event)" :type="passwordInputType" :placeholder="getWord('fill_password')" v-model="password" @keyup.13="test()" />
                            <i class="iconfont icon-hide-password" @click="togglePassword('passwordInputType')" v-if="passwordInputType==='password'"></i>
                            <i class="iconfont icon-show-password" @click="togglePassword('passwordInputType')" v-else></i>
                        </div>
                    </div>
                    <mt-button size="normal" type="primary" class="mt-button1" @click="test()">{{ getWord('sign_in3') }}</mt-button>
                    <div class="clearfix links">
                        <!-- <label><input type="checkbox" />记住密码</label> -->
                        <a @click="active='reg',header.mainTitle=getWord('sign_up4')">{{ getWord('sign_up5') }}</a>
                        <a @click='forgotPass()'>{{ getWord('forget_password') }}?</a>
                    </div>
                </div>
                <div class="reg" v-if="active=='reg'">
                    <fieldset>
                        <label v-if="checkRequired('RECOMMENDCODE_REQUIRED',true) && header.mainTitleId!=='2'" :class="{required:checkRequired('RECOMMENDCODE_REQUIRED')}">
                            <span>{{ getWord('promo_id') }}</span>
                            <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_promo_id')+(checkRequired('RECOMMENDCODE_REQUIRED')?'':'('+ getWord('optional') +')')" v-model="promoCode" :disabled="urlPromoCode?true:false">
                        </label>
                        <label class="required name">
                            <span>{{ getWord('username') }}</span>
                            <b v-if="header.mainTitleId==='2'">d</b>
                            <input @blur="_blured($event)" type="text" :placeholder="getWord('password_rules16',{
                                    $1:'5',
                                    $2:'10'
                                })" v-model="regist_account" />
                        </label>
                        <label class="required">
                            <span>{{ getWord('password3') }}</span>
                            <input @blur="_blured($event)" :type="passwordInputType" :placeholder="getWord('password_rules15',{
                                $1:'8',
                                $2:'12'
                            })" v-model="regist_password" />
                            <i class="iconfont icon-hide-password" @click="togglePassword('passwordInputType')" v-if="passwordInputType==='password'"></i>
                            <i class="iconfont icon-show-password" @click="togglePassword('passwordInputType')" v-else></i>
                        </label>
                        <label v-if="checkRequired('WTPASSWORD_REQUIRED',true) && header.mainTitleId!=='2'" :class="{required:checkRequired('WTPASSWORD_REQUIRED')}">
                            <span>{{ getWord('withdraw_password') }}</span>
                            <input @blur="_blured($event)" :type="passwordInputType2" :placeholder="getWord('password_rules15',{
                                $1:'6',
                                $2:'8'
                            })" v-model="withdrawPassword" />
                            <i class="iconfont icon-hide-password" @click="togglePassword('passwordInputType2')" v-if="passwordInputType2==='password'"></i>
                            <i class="iconfont icon-show-password" @click="togglePassword('passwordInputType2')" v-else></i>
                        </label>
                        <template v-if="header.mainTitleId!=='2'">
                            <label v-if="checkRequired('NAME_REQUIRED',true)" :class="{required:checkRequired('NAME_REQUIRED')}">
                                <span>{{ getWord('real_name') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('real_name_rules')" v-model="regist_name" />
                            </label>
                            <label v-if="checkRequired('PHONE_REQUIRED',true)" :class="{required:checkRequired('PHONE_REQUIRED')}">
                                <span>{{ getWord('mobile_phone2') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_mobile_phone')" v-model="phoneNumber" />
                            </label>
                            <label v-if="checkRequired('QQ_REQUIRED',true)" :class="{required:checkRequired('QQ_REQUIRED')}">
                                <span>{{ getWord('social_app1') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_social_app1_account')" v-model="qqNumber" />
                            </label>
                            <label v-if="checkRequired('EMAIL_REQUIRED',true)" :class="{required:checkRequired('EMAIL_REQUIRED')}">
                                <span>{{ getWord('email2') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_email')" v-model="emailAddress" />
                            </label>
                            <label v-if="checkRequired('WX_REQUIRED',true)" :class="{required:checkRequired('WX_REQUIRED')}">
                                <span>{{ getWord('social_app2_alias') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_social_app2_account')" v-model="wxNumber" />
                            </label>
                        </template>
                        <template v-else>
                            <label v-if="checkRequired('AGENT_NAME_REQUIRED',true)" :class="{required:checkRequired('AGENT_NAME_REQUIRED')}">
                                <span>{{ getWord('real_name') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('real_name_rules')" v-model="regist_name" />
                            </label>
                            <label v-if="checkRequired('AGENT_PHONE_REQUIRED',true)" :class="{required:checkRequired('AGENT_PHONE_REQUIRED')}">
                                <span>{{ getWord('mobile_phone2') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_mobile_phone')" v-model="phoneNumber" />
                            </label>
                            <label v-if="checkRequired('AGENT_QQ_REQUIRED',true)" :class="{required:checkRequired('AGENT_QQ_REQUIRED')}">
                                <span>{{ getWord('social_app1') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_social_app1_account')" v-model="qqNumber" />
                            </label>
                            <label v-if="checkRequired('AGENT_EMAIL_REQUIRED',true)" :class="{required:checkRequired('AGENT_EMAIL_REQUIRED')}">
                                <span>{{ getWord('email2') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_email')" v-model="emailAddress" />
                            </label>
                            <label v-if="checkRequired('AGENT_WX_REQUIRED',true)" :class="{required:checkRequired('AGENT_WX_REQUIRED')}">
                                <span>{{ getWord('social_app2_alias') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_social_app2_account')" v-model="wxNumber" />
                            </label>                            
                        </template>
                        <label v-if="checkRequired('VERITYCODE_REQUIRED',true) && header.mainTitleId!=='2'" :class="{required:true}" class="verify-code">
                            <span>{{ getWord('validation_code') }}</span>
                            <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_validation_code')" v-model="verifyCode" />
                            <img :src="verifyImage" @click="nextVerifyImage" />
                        </label>
                    </fieldset>
                    <div class="terms">
                        <h6><span>*</span>{{ getWord('required_by_symbol') }}</h6>
                        <label>
                            <input type="checkbox" v-model="value" />
                            {{ getWord('regist_terms_kept_informed') }}
                        </label>    
                        <a @click.stop="showAgreement()">{{ getWord('regist_agreement') }}</a>                        
                    </div>
                    <mt-button size="normal" type="primary" class="mt-button1" @click="regist($event)">{{ getWord('submit') }}</mt-button>
                    <p>{{ getWord('already_have_account') }}?<a @click="active='login',header.mainTitle=getWord('sign_in')">{{ getWord('sign_in4') }}</a></p>
                </div>
            </div>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header :mainTitle="header.mainTitle" :backButton=false :serviceIcon=true :backHome=true />
            <div class="main ui-2">
                <div v-if="active==='login'" class="login">
                    <div class="form-body">
                        <div class="fieldset clearfix">
                            <i class="iconfont icon-username"></i>
                            <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_username2')" v-model="account" />
                        </div>
                        <div class="fieldset clearfix">
                            <i class="iconfont icon-password"></i>
                            <input @blur="_blured($event)" :type="passwordInputType" :placeholder="getWord('fill_password')" v-model="password" @keyup.13="test()" />
                        </div>
                    </div>
                    <div class="clearfix links">
                        <!-- <label><input type="checkbox" />记住密码</label> -->
                        <a @click='Mint.Toast(getWord("contact_service3"),1000)'>{{ getWord('forget_password') }}?</a>
                    </div>
                </div>
                <div class="reg" v-if="active=='reg'">
                    <fieldset>
                        <label v-if="checkRequired('RECOMMENDCODE_REQUIRED',true) && header.mainTitleId!=='2'" :class="{required:checkRequired('RECOMMENDCODE_REQUIRED')}">
                            <span>{{ getWord('promo_id') }}</span>
                            <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_promo_id')+(checkRequired('RECOMMENDCODE_REQUIRED')?'':'('+ getWord('optional') +')')" v-model="promoCode" :disabled="urlPromoCode?true:false">
                        </label>
                        <label class="required name">
                            <span>{{ getWord('username') }}</span>
                            <b v-if="header.mainTitleId==='2'">d</b>
                            <input @blur="_blured($event)" type="text" :placeholder="getWord('password_rules16',{
                                $1:'5',
                                $2:'10'
                            })" v-model="regist_account" />
                        </label>
                        <label class="required">
                            <span>{{ getWord('password3') }}</span>
                            <input @blur="_blured($event)" :type="passwordInputType" :placeholder="getWord('password_rules16',{
                                $1:'8',
                                $2:'12'
                            })" v-model="regist_password" />
                            <i class="iconfont icon-hide-password" @click="togglePassword('passwordInputType')" v-if="passwordInputType==='password'"></i>
                            <i class="iconfont icon-show-password-template-3" @click="togglePassword('passwordInputType')" v-else></i>
                        </label>
                        <label v-if="checkRequired('WTPASSWORD_REQUIRED',true) && header.mainTitleId!=='2'" :class="{required:checkRequired('WTPASSWORD_REQUIRED')}">
                            <span>{{ getWord('withdraw_password') }}</span>
                            <input @blur="_blured($event)" :type="passwordInputType2" :placeholder="getWord('password_rules17',{
                                $1:'6',
                                $2:'8'
                            })" v-model="withdrawPassword" />
                            <i class="iconfont icon-hide-password" @click="togglePassword('passwordInputType2')" v-if="passwordInputType2==='password'"></i>
                            <i class="iconfont icon-show-password-template-3" @click="togglePassword('passwordInputType2')" v-else></i>
                        </label>
                        <template v-if="header.mainTitleId!=='2'">
                            <label v-if="checkRequired('NAME_REQUIRED',true)" :class="{required:checkRequired('NAME_REQUIRED')}">
                                <span>{{ getWord('real_name') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('real_name_rules')" v-model="regist_name" />
                            </label>
                            <label v-if="checkRequired('PHONE_REQUIRED',true)" :class="{required:checkRequired('PHONE_REQUIRED')}">
                                <span>{{ getWord('mobile_phone2') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_mobile_phone')" v-model="phoneNumber" />
                            </label>
                            <label v-if="checkRequired('QQ_REQUIRED',true)" :class="{required:checkRequired('QQ_REQUIRED')}">
                                <span>{{ getWord('social_app1') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_social_app1_account')" v-model="qqNumber" />
                            </label>
                            <label v-if="checkRequired('EMAIL_REQUIRED',true)" :class="{required:checkRequired('EMAIL_REQUIRED')}">
                                <span>{{ getWord('email2') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_email')" v-model="emailAddress" />
                            </label>
                            <label v-if="checkRequired('WX_REQUIRED',true)" :class="{required:checkRequired('WX_REQUIRED')}">
                                <span>{{ getWord('social_app2_alias') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_social_app2_account')" v-model="wxNumber" />
                            </label>
                        </template>
                        <template v-else>
                            <label v-if="checkRequired('AGENT_NAME_REQUIRED',true)" :class="{required:checkRequired('AGENT_NAME_REQUIRED')}">
                                <span>{{ getWord('real_name') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('real_name_rules')" v-model="regist_name" />
                            </label>
                            <label v-if="checkRequired('AGENT_PHONE_REQUIRED',true)" :class="{required:checkRequired('AGENT_PHONE_REQUIRED')}">
                                <span>{{ getWord('mobile_phone2') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_mobile_phone')" v-model="phoneNumber" />
                            </label>
                            <label v-if="checkRequired('AGENT_QQ_REQUIRED',true)" :class="{required:checkRequired('AGENT_QQ_REQUIRED')}">
                                <span>{{ getWord('social_app1') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_social_app1_account')" v-model="qqNumber" />
                            </label>
                            <label v-if="checkRequired('AGENT_EMAIL_REQUIRED',true)" :class="{required:checkRequired('AGENT_EMAIL_REQUIRED')}">
                                <span>{{ getWord('email2') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_email')" v-model="emailAddress" />
                            </label>
                            <label v-if="checkRequired('AGENT_WX_REQUIRED',true)" :class="{required:checkRequired('AGENT_WX_REQUIRED')}">
                                <span>{{ getWord('social_app2_alias') }}</span>
                                <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_social_app2_account')" v-model="wxNumber" />
                            </label>                            
                        </template>
                        <label v-if="checkRequired('VERITYCODE_REQUIRED',true) && header.mainTitleId!=='2'" :class="{required:true}" class="verify-code">
                            <span>{{ getWord('validation_code') }}</span>
                            <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_validation_code')" v-model="verifyCode" />
                            <img :src="verifyImage" @click="nextVerifyImage" />
                        </label>
                    </fieldset>
                    <div class="terms">
                        <!-- <h6><span>*</span>号为必填项</h6> -->
                        <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
                            <label>
                                <input type="checkbox" v-model="value" />
                                {{ getWord('regist_terms_kept_informed') }}
                            </label>
                            <a>{{ getWord('regist_agreement') }}</a>
                        </template>
                        <template v-if="_TEMPLATE==='template-3'">
                            <label v-if="this.header.mainTitleId!=='2'">
                                <input type="checkbox" v-model="value" />
                                {{ getWord('regist_terms_kept_informed') }}
                            </label>
                            <a @click.stop="showAgreement()">{{ getWord('regist_agreement') }}</a>
                        </template>
                    </div>
                </div>
                <div class="buttons">
                    <a class="login" type="submit" v-if="active!=='reg'" @click='test()'>{{ getWord('sign_in5') }}</a>
                    <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
                        <a @click="regist($event)">{{ getWord('sign_up3') }}</a>
                        <a @click="$router.push('/home')">{{ getWord('back_to_home') }}</a>
                    </template>
                    <template v-if="_TEMPLATE==='template-3'">
                        <a @click="regist($event)" v-if="this.header.mainTitleId==='2'">{{ getWord('submit') }}</a>   
                        <a @click="regist($event)" v-else>{{ getWord('sign_up3') }}</a>
                        <a @click="$router.push('/home')" v-if="this.header.mainTitleId!=='2'">{{ getWord('back_to_home') }}</a>
                    </template>
                    
                </div>
                <p v-if="active==='reg' && _TEMPLATE==='template-3' && this.header.mainTitleId!=='2'"><a @click="active='login',header.mainTitle=getWord('sign_in')">{{ getWord('sign_in6') }}</a></p>
            </div>
        </template>
        <!-- </form> -->
        <transition name="slide-fade">
            <aside v-if="regdone">
                <div class="inner clearfix">
                    <h4>{{ getWord('contact_service4') }}</h4>
                    <p>{{ getWord('account2') }}d{{regist_account}}</p>
                    <a @click="$router.push('/home')">{{ getWord('ok') }}</a>
                </div>
            </aside>
        </transition>

        <transition name="slide-fade">
            <aside v-if="agreementToggle" class="agreement">
                <div class="inner clearfix">
                    <i class="iconfont2 icon2-close icon-close2" @click.stop="showAgreement()"></i>
                    <h4>{{ getWord('regist_agreement') }}</h4>
                    <div class="content" v-html="agreementContent"></div>
                </div>
            </aside>
        </transition>
    </section>
</template>
<style scoped lang='scss'>
@import "@@/assets/style/_variables";
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(100%);
    opacity: 0;
}

#login {
    color: #252525;
    overflow: auto;
    display: flex;
    flex-direction: column;

    > form {
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
    }

    &.template-1,
    &.template-2,
    &.template-3 {
        height: 100%;

        .main {
            height: 100%;

            .middle {

                .land_middle_text {

                    .middle_text1 {

                        &.verify-code {
                            padding-top: .4rem;

                            .middle_text1_input {
                                width: 3rem;
                            }

                            img {
                                display: inline-block;
                                width: 2.5rem;
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }

            &.agent-reg {

                .header,
                .middle_firstbt,
                .middle_firstbt_1 {
                    display: none;
                }

                .middle {
                    margin:0;
                    border:0;
                    box-shadow: none;
                    width: auto;
                    border-radius: 0;

                    .land_middle_text {
                        margin-top: 0;

                        .middle_text1 {

                            &.name {

                                b {
                                    position: relative;
                                    right: -0.25rem;
                                }
                            }

                            &.verify-code {                            

                                .middle_text1_input {
                                    width: 3rem;
                                }
                            }
                        }
                    }
                }
            }

            &.ui-2 {
                background-color: #F5F5F9;

                .reg {

                    fieldset {
                        background-color: #ffffff;
                        border:0;
                        padding: 0 .125rem;
                        margin-bottom: .25rem;

                        label {
                            display: flex;
                            align-items: center;
                            font-size: .3rem;

                            &.name {

                                b {
                                    position: relative;
                                    right: -.24rem;
                                }
                            }

                            &.verify-code {

                                input {
                                    width: 3rem;
                                }

                                img {
                                    display: inline-block;
                                    width: 2.7rem;
                                    vertical-align: middle;
                                }
                            }

                            &.required {

                                span {

                                    &:before {
                                        content:'*';
                                        color: #FF0F0F;
                                    }
                                }
                            }

                            span {
                                display: inline-block;
                                width: 1.5rem;

                                &:before {
                                    content:'*';
                                    color: transparent;
                                }
                            }

                            input {
                                float: none;
                                width: 5rem;
                                height: .85rem;
                                border:0;                        
                                box-sizing: border-box;
                                padding: 0 .25rem;
                                margin: 0;
                                font-size: .3rem;

                                &:focus {
                                    background-color: #FAFFBD;
                                }
                            }

                            .iconfont {
                                font-size: .4rem;
                                text-align: center;
                                display: inline-block;
                                width: 0.7rem;
                                vertical-align: middle;
                            }
                        }
                    }

                    .terms {
                        padding: 0 .3rem;
                        color: #CCCCCC;

                        h6 {
                            font-weight: normal;
                            margin-bottom: .1rem;
                            font-size: .28rem;

                            span {
                                color: #FF0F0F;
                            }
                        }

                        label {
                            font-size: .28rem;

                            
                        }

                        a {
                            font-size: .28rem;
                            color: $TEMPLATE2-theme-color;
                        }
                    }

                    p {
                        text-align: center;
                        font-size: .3rem;
                        color: #cccccc;

                        a {
                            color: $TEMPLATE2-theme-color;
                        }
                    }
                }

                .form-body {
                    background-color: #ffffff;

                    .fieldset {
                        margin-bottom: 2px;

                        > img {
                            width: .33rem;
                            padding: 0rem .2rem 0 .3rem;
                            vertical-align: -2px;
                        }

                        svg {
                            vertical-align: -.075rem;
                            display: inline-block;
                            height: 0.35rem;
                            width: 0.4rem;
                            background-size: 100%;
                            margin:0 .3rem;
                        }

                        input {
                            width: 5.6rem;
                            height: .85rem;
                            border:0;                        
                            box-sizing: border-box;
                            padding: 0 .25rem;
                            font-size: .32rem;

                            &:focus {
                                background-color: #FAFFBD;
                            }
                        }

                        .iconfont {
                            font-size: .4rem;
                            text-align: center;
                            display: inline-block;
                            width: 0.9rem;
                            vertical-align: middle;
                        }
                    }
                }

                .mt-button1 {
                    float: none;
                    width: 96%;
                    display: block;
                    border-radius: 0;
                    margin: .3rem auto;
                    font-size: .34rem;
                }

                .links {
                    width: 96%;
                    margin: 0 auto;
                    font-size: .3rem;

                    label {
                        display: block;
                        margin-bottom: .25rem;

                        input {
                            margin-right: .1rem;
                        }
                    }

                    a {
                        color: $TEMPLATE2-theme-color;
                        display: inline-block;
                        width: 48%;
                        text-align: center;
                    }
                }
            }
        }
    }

    &.template-2 {

    	.main {

    		&.ui-2 {

    			.form-body {

    				.fieldset {
    					display: flex;
    					align-items: center;
    				}
    			}
    		}
    	}

        
    }

    &.template-3 {
        height: 100%;

        .main {

            &.ui-2 {
                flex-grow: 1;
                background-color: #EEEEEE;

                .form-body {

                    .fieldset {

                        .iconfont {
                            width: .5rem;
                        }
                    }
                }

                .reg {
                    margin-bottom: .5rem;

                    fieldset {
                        padding: 0 .25rem;

                        label {
                            border-top: 1px solid #B0B0B0;

                            &:first-child {
                                border-top:0;
                            }

                            &.verify-code {

                                img {
                                    width: 2.5rem;
                                }
                            }

                            input {
                                width: 4.8rem;
                            }
                        }
                    }

                    .terms {
                        color: #5F646E;
                    }
                }

                .links {
                    padding: .25rem;
                    font-size: .28rem;
                    color: #5F646E;
                    width: auto;

                    input {
                        margin-right: .1rem;
                    }

                    label {
                        float:left;
                    }

                    a {
                        color: #5F646E;
                        float: right;
                        width: auto;

                    }
                }
            }

            .form-body {
                padding: 0 .25rem;
                background-color: #ffffff;

                .fieldset {
                    padding: .075rem 0;
                    border-top: 1px solid #B0B0B0;
                    display: flex;
                    align-items: center;

                    &:first-child {
                        border-top:0;
                    }

                    span {
                        flex-basis: 1.5rem;
                        font-size: .3rem;
                        color: #333333;
                    }

                    input {
                        border:0;                        
                        box-sizing: border-box;
                        padding: 0 .25rem;
                        font-size: .3rem;
                        height: .48rem;
                        vertical-align: middle;
                        width: 100%;

                        &:focus {
                            background-color: #FAFFBD;
                        }

                        &::placeholder {
                            color: #B0B0B0;
                        }
                    }

                    .iconfont {
                        font-size: .4rem;
                        text-align: center;
                        display: inline-block;
                        color: #B0B0B0;
                        vertical-align: middle;
                    }
                }
            }

            .buttons {
                padding: 0 .5rem;

                :first-child {
                    font-size: .34rem;
                    background-color: #EC2829;
                    color: #ffffff;
                }

                a,button {
                    width: 100%;
                    border: 0;
                    display: block;
                    font-size: .34rem;
                    color: #EC2829;
                    background-color: #ffffff;
                    border-radius: .1rem;
                    margin-top: .25rem;
                    text-align: center;
                    padding: .2rem 0;
                }
            }

            > p {
                text-align: right;
                padding: .25rem .5rem;

                a {
                    color: #5F646E;
                    font-size: .26rem;
                }
            }
        }
    }

    aside {

        &.agreement {

            h4 {
                text-align: center;
                font-size: .3rem;
                margin-bottom: .25rem;
                margin-top: -.14rem;
            }

            .icon2-close {
                position: absolute;
                top: .25rem;
                right: .25rem;
            }

            .content {
                max-height: 70%;
                overflow: auto;
            }
        }
    }

    .myposition {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }

    .header {
        width: 2.5rem;
        height: 2.5rem;
        margin: 0.8rem auto;
        position: relative;

        img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .middle {
        padding-bottom: .5rem;
        width: 6.6rem;
        margin: 0 auto;
        border: 0.01px solid #ccc;
        border-radius: 0.26rem;
        margin-top: 0.7rem;
        background-color: white;
        box-shadow: 0 0 0.05rem #ccc;
    }

    .middle_firstbt {
        display: flex;
        justify-content: space-evenly;
        padding-top: .5rem;

        .middle_firstbt_1,
        .middle_firstbt_2 {
            font-size: 0.34rem;
            color: rgba(153, 153, 153, 1);
            padding:0.1rem;
        }

        .middle_firstbt_1 a {

            &.active {
                color:rgba(71,150,252,1);
                padding:0.1rem;
                border-bottom:0.03rem solid rgba(71,150,252,1);
            }
        }

        .middle_firstbt_2 a {

            &.active {
                color:rgba(71,150,252,1);
                border-bottom:0.03rem solid rgba(71,150,252,1);
                padding:0.1rem;
            }
        }
    }

    .land_middle_text {
        width: 90%;
        margin: .25rem auto;

        .middle_text1 {
            padding: 0.5rem 0 0;
            border-bottom: 0.03rem solid #eee;

            &.required {

                &:before {
                    content:'*';
                    color: red;
                }
            }        

            svg {
                float: left;
                height: 0.35rem;
                width: 0.4rem;
                background-size: 100%;
                margin-top: 0.7rem;
                fill: #aaaaaa;
            }

            .middle_text1_image {
                background-image: url('/static/assets/images2/user/login/username.png');
                height: 0.35rem;
                width: 0.4rem;
                background-size: 100%;
                display: inline-block;
                vertical-align: middle;
            }

            .middle_text2_image {
                height: 0.35rem;
                width: 0.4rem;
                background-image: url('/static/assets/images2/user/login/password.png');
                background-size: 100%;            
                display: inline-block;
                vertical-align: middle;
            }

            .middle_text1_input {
                margin-left: .25rem;
                width: 5rem;
                height: 0.6rem;
                border: none;
                line-height: 0.6rem;
                font-size: 0.26rem;
                outline: none;
            }
        }
    }

    .mt-button1 {    
        height: 0.88rem;
        margin-top: 0.6rem;
        border-radius: 0.8rem;
        margin: .6rem auto;
        display: block;
        width: 90%;
    }

    .middle_footer {
        width: 100%;
        height: 0.5rem;
        margin-top: 0.4rem;
    }

    .middle_footer input {
        float: left;
        height: 100%;
    }

    .middle_footer span {
        float: left;
        height: 100%;
        line-height: 0.6rem;
        font-size: 0.1rem;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
    }

    .middle_footer a {
        float: right;
        height: 100%;
        line-height: 0.6rem;
        font-size: 0.1rem;
        color: #4796FC;
    }

    .middle_footer_mt-switch {
        float: left;
        width: 50%;
    }

    .middle_footer_mt-switch2 {
        float: left;
    }

    aside {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 11;

        a {
            color: #ffffff;
            font-size: .3rem;
            text-align: right;
        }

        .scrolled-block {
            overflow: auto;
            max-height: 50vh;
        }

        .inner {
            position: relative;
            width: 5.75rem;
            border-radius: 5px;
            color:#ffffff;
            background-color: #484848;
            box-shadow: 0px 1px 10px 0 rgba(0, 0, 0, .2);
            max-height: 70vh;
            overflow: auto;
            display: flex;
            flex-direction: column;
            padding: .33rem .5rem;
        }

        h4,p {
            font-size: .26rem;
            font-weight: 500;
            margin-bottom: .1rem;

            span {
                font-size: .24rem;
                display: block;
                color: #999999;
            }
        }

        h6 {
            font-size: .28rem;
            font-weight: 500;
            text-align: right;
            padding: .25rem;

            b {
                font-weight: 500;
                color: #E83535;
            }
        }

        table {
            width: 100%;
            padding: 0 .25rem;
            text-align: center;
            font-size: .28rem;
            border-collapse: collapse;
            box-sizing: border-box;

            tr {
                border-bottom: 1px solid #f3f3f4;
            }

            th {
                border: 1px solid #EBEAF1;
                border-left: 0;
                border-right: 0;
                font-size: .24rem;
                background-color: #F3F3F4;
                color: #999999;
                padding: .2rem 0;
            }

            td {
                padding: .2rem 0;
            }
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            padding: 0 1rem;
            list-style: none;
            justify-content: space-between;

            li {
                filter: brightness(.5);
                margin-bottom: .125rem;

                &.highlight {
                    filter: none;
                }

                img {
                    width: .88rem;
                    height: .88rem;
                }
            }
        }

        footer {
            display: flex;
            width: 100%;
            border-top: 1px solid #f3f3f4;

            button {
                font-size: .32rem;
                flex-grow: 1;
                border: 0;
                background-color: transparent;
                padding: .25rem 0;
                color: #3985E1;
                outline: none;

                &:first-child {
                    border-right: 1px solid #f3f3f4;
                }
            }
        }

        &:before {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: fixed;
            background-color: rgba(0, 0, 0, .2);
            content: "";
            cursor: default;
        }
    }
}
</style>